document.addEventListener('DOMContentLoaded', function () {
  const navFolders = document.querySelector('#nav-folders');

  if (!navFolders) {
    return;
  }

  const foldersLinks = document.querySelectorAll('.folder-link-trigger');
  const foldersContents = document.querySelectorAll('.folder-content');
  const backFolders = document.querySelectorAll('.back-folders');
  const shareLinks = document.querySelectorAll('.share-link');

  foldersLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();

      const linkId = link.getAttribute('href')?.replace('#', '');

      foldersContents.forEach((content) => {
        const contentId = content.getAttribute('id');

        if (contentId == linkId) {
          content.classList.add('show');
        } else {
          content.classList.remove('show');
        }
      });

      navFolders.classList.add('hide');
    });
  });

  backFolders.forEach((link) => {
    link.addEventListener('click', (e) => {
      foldersContents.forEach((content) => {
        e.preventDefault();

        content.classList.remove('show');
        navFolders.classList.remove('hide');
      });
    });
  });

  shareLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();

      // Open the native share dialog
      void window.navigator
        .share({
          title: 'Partage de document',
          text: 'Partage de document',
          url: (e.target as HTMLAnchorElement).href,
        })
        .then(() => {});
    });
  });
});
