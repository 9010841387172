document.addEventListener('DOMContentLoaded', () => {
  const readMore = document.querySelector('.read-more');

  if (readMore) {
    readMore.addEventListener('click', (e) => {
      e.preventDefault();

      const trigger = document.querySelector('[data-bs-toggle="tab"][data-bs-target="#description-pane"]');
      if (trigger) {
        (trigger as HTMLButtonElement).click();

        // Smooth scroll to the description
        const description = document.querySelector('#description-pane');
        if (description) {
          description.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }
});
